<template>
  <a-modal
    :get-container="modalRef"
    v-model:visible="visible"
    :mask-closable="false"
    title="编辑pda"
    :after-close="afterClose"
  >
    <template #footer>
      <a-button :disabled="disabled" @click="visible = false">取消</a-button>
      <a-button :disabled="disabled" type="primary" @click="doSubmit"
        >确定</a-button
      >
    </template>
    <a-form :label-col="{ span: 4 }">
      <a-form-item label="pda编号" v-bind="validateInfos.code">
            <a-input
                    v-model:value="pdaVo.code"
                    :maxlength="50"
                    placeholder="pda编号"
                    allow-clear
            ></a-input>
        </a-form-item>
      <a-form-item label="设备名称" v-bind="validateInfos.name">
        <a-input
          v-model:value="pdaVo.name"
          :maxlength="20"
          placeholder="设备名称"
          allow-clear
        ></a-input>
      </a-form-item>
      <a-form-item label="状态">
        <a-select
          v-model:value="pdaVo.state"
          placeholder="请选择"
          allow-clear
          :options="pdaStates"
        ></a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { ref, onMounted, toRefs} from "vue";
import useValidateRules from "@/hooks/useValidateRules";
import useMessage from "@/hooks/useMessage";
import { Form } from "ant-design-vue";
import { detail,edit } from "@/api/maintenance/pdaApi";
import usePda from "@/hooks/maintenance/usePda";

const useForm = Form.useForm;
export default {
  name: "Edit",
  props: ["modalRef", "id"],
  emits: ["close"],
  setup(props, { emit }) {
    const disabled = ref(false);
    const { pdaVo, pdaStates } = usePda();
    const { pdaRules } = useValidateRules();
    const { showErr, showConfirm, showLoading, closeLoading, showTipMessage } =
      useMessage();
    const { validateInfos, validate } = useForm(
      pdaVo,
      pdaRules,
    );
    const visible = ref(true);
    let refresh = false;
    const afterClose = () => {
      emit("close", refresh);
    };
    const doSubmit = () => {
      validate().then(async () => {
        let action = await showConfirm("修改pda设备信息?");
        if (action) {
          disabled.value = true;
          await showLoading();
          try {
            let obj = {
                name: pdaVo.name,
                code: pdaVo.code,
                state: pdaVo.state,
            };
            await edit(props.id, obj);
            await closeLoading();
            await showTipMessage("修改成功");
            refresh = true;
            visible.value = false;
          } catch (e) {
            await closeLoading();
            await showErr(e);
            disabled.value = false;
          }
        }
      });
    };
    onMounted(async () => {
        try {
            let { data } = await detail(props.id);
            pdaVo.name = data.name;
            pdaVo.code = data.code;
            pdaVo.state = data.state;
        } catch (e) {
            await showErr(e);
        }
    });
    return {
      ...toRefs(props),
      disabled,
      visible,
      afterClose,
      pdaVo,
      pdaStates,
      validateInfos,
      doSubmit,
    };
  },
};
</script>

<style scoped></style>
